import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { t } from '../../generated/i18n';
import { useFilter } from '../filter/filter-context';
import { CurrentFilter } from './filter-actions';
import FilterForm from './form';

const FilterDialog = () => {
  const {
    dialogVisible,
    closeFilterDialog,
    state: { aggregations },
  } = useFilter();
  return (
    <>
      <Transition.Root show={dialogVisible} as={Fragment}>
        <Dialog
          as='div'
          className='pointer-events-none fixed inset-0 left-0 z-20 flex justify-center bg-white'
          onClose={closeFilterDialog}
        >
          <Transition.Child
            as={'div'}
            enter='transform transition ease-in-out duration-300'
            enterFrom='translate-y-full sm:-translate-y-0 sm:-translate-x-full'
            leave='transform transition ease-in-out duration-300'
            enterTo='translate-y-0 sm:-translate-y-0 sm:-translate-x-0'
            leaveFrom='translate-y-0 sm:translate-y-0 sm:-translate-x-0'
            leaveTo='translate-y-full sm:translate-y-0 sm:-translate-x-full'
          >
            <Dialog.Panel
              className={`max-w-8xl pointer-events-auto mx-auto mt-[100px] h-[calc(100vh-160px)] w-screen overflow-auto pb-[150px] xs:mt-[96px] lg:mt-[114px] lg:h-[calc(100vh-140px)] lg:max-h-none lg:px-8 lg:pb-0`}
              as={'div'}
            >
              <button
                type='button'
                className='absolute right-6 top-[7rem] z-20 bg-white p-3 lg:top-36'
                onClick={() => closeFilterDialog(false)}
              >
                <span className='sr-only'>{t('close')}</span>
                <svg
                  width='21'
                  height='21'
                  viewBox='0 0 21 21'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1 19.8687L19.8687 1'
                    stroke='#231F20'
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                  />
                  <path
                    d='M19.8687 19.8687L1 1'
                    stroke='#231F20'
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                  />
                </svg>
              </button>

              <div className='filter-dialog h-[calc(100vh+20px)] text-[18px] lg:h-[calc(100vh-114px)] lg:text-base'>
                <FilterForm />
              </div>

              <nav className='absolute bottom-0 w-full bg-white lg:bottom-6 lg:right-0 lg:ml-auto lg:mr-6 lg:w-[260px]'>
                <CurrentFilter className='mb-4 hidden flex-col gap-1 lg:flex' />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    closeFilterDialog();
                  }}
                  className='w-full whitespace-nowrap bg-green py-4 font-bold uppercase text-white transition duration-200  hover:bg-black lg:rounded-md lg:bg-green lg:px-4'
                >
                  {aggregations.resultCount}{' '}
                  {aggregations.resultCount == 1
                    ? t('applyfiltersingular')
                    : t('applyFilter')}
                </button>
              </nav>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default React.memo(FilterDialog);
